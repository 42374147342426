var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"left":"","transition":"scale-transition","offset-y":"","bottom":"","rounded":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('a',_vm._g(_vm._b({staticClass:"btn btn-light-light btn-hover-secondary show poppins min-w-100px min-w-md-110px"},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("actions"))+" "),_c('span',{staticClass:"svg-icon m-0"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-down")])],1)])]}}])},[_c('v-card',{staticClass:"poppins py-1",attrs:{"elevation":"1"}},[_vm._l((_vm.actions.filter(
        (action) => action.isVisible === true
      )),function(action,index){return [_c('div',{key:index},[_c('v-btn',{staticClass:"w-100 text-left",class:!_vm.actionStatus(action) ? 'text-muted' : 'text-dark',attrs:{"disabled":!_vm.actionStatus(action),"text":""},on:{"click":() => {
              if (_vm.item.status_label == 'Unsubscribed') {
                return;
              } else {
                _vm.handle_function_call(action.method, _vm.item);
              }
            }}},[(action.type !== 'status')?_c('span',[_vm._v(_vm._s(action.title))]):(_vm.item.is_active)?_c('span',[_vm._v(" "+_vm._s(_vm.item.is_active === "Yes" || _vm.item.is_active === "YES" ? _vm.$t("suspend") : _vm.$t("unsuspend"))+" ")]):(_vm.item.status_label)?_c('span',{class:{
              'text-muted':
                _vm.item.status_label === 'Cancelled' ||
                _vm.item.status_label == 'Unsubscribed',
            }},[_vm._v(" "+_vm._s(_vm.statusLabel)+" ")]):(_vm.item.state_label)?_c('span',[_vm._v(" "+_vm._s(_vm.item.state_label !== "Active" ? _vm.$t("unsuspend") : _vm.$t("suspend"))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.item.status !== "Suspended" ? _vm.$t("suspend") : _vm.$t("unsuspend"))+" ")])])],1)]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }